import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const ImageWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    margin: 25px 0;
  }

  @media print {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: unset;
    max-height: unset;
    max-width: unset;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto 0 auto;

  @media print {
    display: none;
  }
`;

const Button = styled.button`
  width: 150px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #000000;
  font-size: ${({ theme }) => theme.fontOptions.smallText.size};
  line-height: ${({ theme }) => theme.fontOptions.smallText.lineHeight};
  font-family: "Poppins", sans-serif;
  padding: 10px 15px;
  display: inline-block;
  border: none;
  border-radius: 40px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  max-width: 200px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 10px;
  :hover {
    background-color: ${({ theme }) => theme.colors.primaryLight};
    color: #ffffff;
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #ffffff;
  }

  &:first-child {
    margin-right: 15px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  display: none !important;

  @media print {
    display: inline-block;
    height: auto;
  }

  &.active {
    display: inline-block !important;
  }
`;

const HousePageVis = ({ dummyImage, floor, setFloor, firstFloorView, secondFloorView }) => {
  const handleButton = (e) => {
    setFloor(e);
  };
  return (
    <ImageWrapper>
      <StyledGatsbyImage className={floor === 1 && "active"} image={firstFloorView} alt="Osiedle Melody Park" />
      <StyledGatsbyImage className={floor === 2 && "active"} image={secondFloorView} alt="Osiedle Melody Park" />

      <Buttons>
        <Button onClick={(e) => handleButton(1)} className={floor === 1 && "active"}>
          Parter
        </Button>
        <Button onClick={(e) => handleButton(2)} className={floor === 2 && "active"}>
          Poddasze
        </Button>
      </Buttons>
    </ImageWrapper>
  );
};

export default HousePageVis;
