import React from "react";
import styled from "styled-components";
import { NormalText } from "../../atoms/NormalText/NormalText";

const FloorDetails = styled.div``;

const StyledListWrapper = styled.div`
  display: none;
  margin-top: 25px;

  @media print {
    display: block;
  }

  &.active {
    display: block;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const StyledListItem = styled.li`
  border-bottom: 2px solid ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  padding: 10px 0 3px 0;
  font-size: ${({ theme }) => theme.fontOptions.smallText.size};
  &:last-child {
    border: none;
  }
`;

const ListParagraph = styled.p`
  text-align: right;
  margin: 0;
  &.isBold {
    font-weight: bold;
    margin-left: auto;
  }
`;

const ListOrder = styled.span`
  margin-right: 20px;
  width: 10px;
`;

const StyledNormalText = styled(NormalText)`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontOptions.headLine.fontWeight};
  font-size: ${({ theme }) => theme.fontOptions.subHeadLineThree.size};
`;

const HousePageDetails = ({ detailsQuery, floor }) => {
  return (
    <FloorDetails>
      <StyledListWrapper className={floor === 1 && "active"}>
        <StyledNormalText>Parter</StyledNormalText>
        <StyledList>
          {detailsQuery.firstFloor.map((node, index) => (
            <StyledListItem>
              <ListOrder>{node.number}</ListOrder>
              <ListParagraph>{node.name}:</ListParagraph>
              <ListParagraph className="isBold">{node.area} m2</ListParagraph>
            </StyledListItem>
          ))}
        </StyledList>
      </StyledListWrapper>

      <StyledListWrapper className={floor === 2 && "active"}>
        <StyledNormalText>Poddasze</StyledNormalText>
        <StyledList>
          {detailsQuery.secondFloor.map((node, index) => (
            <StyledListItem>
              <ListOrder>{node.number}</ListOrder>
              <ListParagraph>{node.name}:</ListParagraph>
              <ListParagraph className="isBold">{node.area} m2</ListParagraph>
            </StyledListItem>
          ))}
        </StyledList>
      </StyledListWrapper>
    </FloorDetails>
  );
};

export default HousePageDetails;
