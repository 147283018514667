import React from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import angleLeft from "@iconify/icons-fontisto/angle-left";
import downloadIcon from "@iconify/icons-fontisto/download";
import { SmallText } from "../../atoms/SmallText/SmallText";
import NavAnchorLink from "../../atoms/NavAnchorLink/NavAnchorLink";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 50px;

  @media print {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    padding: 0;
  }
`;

const BackBtn = styled(NavAnchorLink)`
  border: none;
  background: none;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  width: 60px;
  height: 60px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  width: 200px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #000000;
  font-size: ${({ theme }) => theme.fontOptions.smallText.size};
  line-height: ${({ theme }) => theme.fontOptions.smallText.lineHeight};
  font-family: "Poppins", sans-serif;
  padding: 10px 15px;
  border: none;
  border-radius: 40px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  max-width: 200px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${({ theme }) => theme.colors.primaryLight};
    color: #ffffff;
  }

  svg {
    margin-right: 7px;
  }

  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    margin-top: 10px;
  }
`;

const HousePageTopbar = () => {
  const handlePrintClick = () => {
    typeof window !== "undefined" && window.print();
  };
  return (
    <StyledWrapper>
      <BackBtn linkTo="/#houses">
        <IconWrapper>
          <Icon icon={angleLeft} width="20" color="#ffffff" />
        </IconWrapper>
        <SmallText>Powrót</SmallText>
      </BackBtn>
      <Button onClick={handlePrintClick}>
        <Icon icon={downloadIcon} />
        Wydrukuj PDF
      </Button>
    </StyledWrapper>
  );
};

export default HousePageTopbar;
