import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import HousePageVis from "../../components/molecules/HousePageVis/HousePageVis";
import InteractiveVisualization from "../../components/molecules/InteractiveVisualisation/InteractiveVisualisation";
import HousePageTopbar from "../../components/molecules/HousePageTopbar/HousePageTopbar";
import MainLayout from "../MainLayout/MainLayout";
import { HeadLine } from "../../components/atoms/HeadLine/HeadLine";
import { Availability } from "../../types/availability";
import HousePageDetails from "../../components/molecules/HousePageDetails/HousePageDetails";
import { NormalText } from "../../components/atoms/NormalText/NormalText";
import Contact from "../../components/organisms/Contact/Contact";

const SectionWrapper = styled.section`
  width: 90vw;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 100px;
  margin-left: auto;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    flex-direction: column;
    margin-right: auto;
  }
`;

const VisContainer = styled.div`
  width: 60%;
  padding-top: 50px;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 100%;
    padding-top: 0;
  }
`;

const TableContainer = styled.div`
  width: 35%;
  background-color: #f3f3f3;
  padding-right: 15vw;
  padding-left: 10%;
  padding-top: 110px;
  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    width: 100%;
    background-color: transparent;
    padding: 0;
  }
  @media (max-width: ${({ theme }) => theme.mediaPoints.big}) {
    padding-right: 10vw;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const StyledListItem = styled.li`
  border-bottom: 2px solid ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 3px 0;
  font-size: ${({ theme }) => theme.fontOptions.smallText.size};
  &:last-child {
    border: none;
  }
`;

const ListParagraph = styled.p`
  text-align: right;
  margin: 0;
  &.isBold {
    font-weight: bold;
  }

  &.isRed {
    color: #e60000;
  }
`;

const StyledHeadline = styled(HeadLine)`
  font-size: ${({ theme }) => theme.fontOptions.subHeadLineTwo.size};
  z-index: 1;
`;

const HouseStatus = styled.p``;

const StyledStatusSpan = styled.span`
  &.available {
    color: #07542b;
  }
  &.reserved {
    color: #cc8500;
  }
  &.unavailable {
    color: #e60000;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
`;

const StyledPrice = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontOptions.headLine.fontWeight};
  font-size: ${({ theme }) => theme.fontOptions.subHeadLineOne.size};

  @media (max-width: ${({ theme }) => theme.mediaPoints.medium}) {
    font-size: ${({ theme }) => theme.fontOptions.subHeadLineOne.size};
  }
`;

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  padding: 0 5%;
`;

const AlertHeadline = styled(HeadLine)`
  text-align: center;
  ::after {
    display: none;
  }
`;

const HouseLayout = ({ data }) => {
  const [floor, setFloor] = useState(1);

  let defaultWidth;

  if (typeof window !== `undefined`) {
    defaultWidth = window.innerWidth;
  }

  const useWindowSize = () => {
    const [width, setWidth] = useState({
      width: defaultWidth,
    });

    useEffect(() => {
      const handleResize = () =>
        setWidth({
          width: window.innerWidth,
        });

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return width;
  };

  const getClassName = (availability) => {
    switch (availability) {
      case Availability.Available:
        return "available";
      case Availability.Reserved:
        return "reserved";
      case Availability.Unavailable:
        return "unavailable";
    }
  };

  const { width } = useWindowSize();
  return (
    <MainLayout shouldDisplayHeader={true} title={`Dom nr. ${data.datoCmsHouse.number}`}>
      {data.datoCmsHouse.part === "I" ? (
        <AlertWrapper>
          <AlertHeadline>Ten dom nie jest już w sprzedaży</AlertHeadline>
        </AlertWrapper>
      ) : (
        <SectionWrapper>
          <VisContainer>
            <HousePageTopbar />
            {width > 991 && (
              <HousePageVis
                firstFloorView={data.datoCmsHouse.firstfloorView.gatsbyImageData}
                secondFloorView={data.datoCmsHouse.secondfloorView.gatsbyImageData}
                floor={floor}
                setFloor={setFloor}
              />
            )}
          </VisContainer>
          <TableContainer>
            <HouseStatus>
              Status:{" "}
              <StyledStatusSpan className={getClassName(data.datoCmsHouse.availability)}>
                {data.datoCmsHouse.availability}
              </StyledStatusSpan>
            </HouseStatus>
            <StyledHeadline>Dom nr. {data.datoCmsHouse.number}</StyledHeadline>
            <StyledList>
              <StyledListItem>
                <ListParagraph>Powierzchnia:</ListParagraph>
                <ListParagraph className="isBold">{data.datoCmsHouse.area} m2</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Powierzchnia działki:</ListParagraph>
                <ListParagraph className="isBold">{data.datoCmsHouse.parcelArea} m2</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Ilość pokoi:</ListParagraph>
                <ListParagraph className="isBold">{data.datoCmsHouse.roomsNumber}</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Miejsc parkingowych:</ListParagraph>
                <ListParagraph className="isBold">{data.datoCmsHouse.garagePlaces}</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Termin oddania:</ListParagraph>
                <ListParagraph className="isBold">{data.datoCmsHouse.deliveryDate}</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Etap:</ListParagraph>
                <ListParagraph className="isBold isRed">{data.datoCmsHouse.part}</ListParagraph>
              </StyledListItem>
              <StyledListItem>
                <ListParagraph>Inwestor:</ListParagraph>
                <ListParagraph className="isBold isRed">{data.datoCmsHouse.investor}</ListParagraph>
              </StyledListItem>
            </StyledList>
            {width <= 991 && (
              <HousePageVis
                firstFloorView={data.datoCmsHouse.firstfloorView.gatsbyImageData}
                secondFloorView={data.datoCmsHouse.secondfloorView.gatsbyImageData}
                floor={floor}
                setFloor={setFloor}
              />
            )}
            <HousePageDetails detailsQuery={data.datoCmsHouse} floor={floor} />
            <PriceWrapper>
              <NormalText>Cena:</NormalText>
              <StyledPrice>{data.datoCmsHouse.price} zł</StyledPrice>
            </PriceWrapper>
          </TableContainer>
        </SectionWrapper>
      )}
      <InteractiveVisualization />
      <Contact />
    </MainLayout>
  );
};

export const query = graphql`
  query HouseTemplate($number: String!) {
    datoCmsHouse(number: { eq: $number }) {
      number
      availability
      price
      area
      parcelArea
      roomsNumber
      garagePlaces
      deliveryDate(formatString: "DD/MM/YYYY")
      investor
      part
      firstfloorView {
        gatsbyImageData
      }
      secondfloorView {
        gatsbyImageData
      }
      firstFloor {
        name
        number
        area
      }
      secondFloor {
        name
        number
        area
      }
    }
    View2DFirstFloor: file(name: { eq: "2D_Vis_Left" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, formats: [WEBP, JPG], layout: CONSTRAINED)
      }
    }
  }
`;

export default HouseLayout;
